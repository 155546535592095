import datatableFormatter from '@clickadilla/components/utils/datatable-formatter.js'
import http from '../http.js'

export default {
  activate: async ({ ids }) => {
    await http.post('/api/private/v1/campaigns/activate', {
      campaign_ids: ids
    })
  },
  pause: async ({ ids }) => {
    await http.post('/api/private/v1/campaigns/pause', { campaign_ids: ids })
  },
  delete: async ({ ids }) => {
    await http.post('/api/private/v1/campaigns/delete', { campaign_ids: ids })
  },
  show: async (id) => {
    const response = await http.get(`/api/private/v1/campaigns/${id}`)
    return response.data.data
  },
  showWithoutModel: async (id) => {
    const { data } = await http.get(`/api/private/v1/campaigns/${id}`)
    return data.data
  },
  store: async (params) => {
    const { data } = await http.post('/api/private/v1/campaigns', params)
    return data.data
  },
  offers: async (id) => {
    const { data } = await http.get(`/api/private/v1/campaigns/${id}/offers`)
    return data.data
  },
  update: async (campaignId, params) => {
    const { data } = await http.put(`/api/private/v1/campaigns/${campaignId}`, params)
    return data.data
  },
  blacklistRequest: async (target, params) => {
    await http.post(`/api/private/v1/campaigns/targets/${target}/blacklist`, params)
  },
  addRule: async (campaignId, params) => {
    await http.post(`/api/private/v1/campaigns/${campaignId}/updating-rules`, params)
  },
  deleteRule: async (campaignId, ruleId) => {
    await http.delete(`/api/private/v1/campaign-updating-rules/${ruleId}`)
  },
  updateRule: async (campaignId, ruleId, params) => {
    await http.put(`/api/private/v1/campaign-updating-rules/${ruleId}`, params)
  },
  async price(campaignId, params) {
    this.price.abort?.()
    await http.post(`/api/private/v1/campaigns/${campaignId}/price`, params, {
      cancelToken: new http.CancelToken((abort) => {
        this.price.abort = abort
      })
    })
  },
  async campaignsTable(params) {
    this.campaignsTable.abort?.()

    const { data } = await http.get('/api/private/v1/campaigns', {
      params: datatableFormatter(params),
      cancelToken: new http.CancelToken((abort) => {
        this.campaignsTable.abort = abort
      })
    })
    return {
      items: data.data,
      totalItemsCount: data.recordsFiltered
    }
  }
}
