import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
import http from '../http.js'

const undefinedNumberHandler = (value) => {
  if (value === undefined) return 0

  return toFixedByDefault(Number(value))
}

export default {
  list({
    dates, selectedCampaigns, adFormats = [], groupBy, campaignStatuses
  }) {
    this.list.abort?.()
    const adFormatsTypes = adFormats.reduce((acc, item) => {
      if (!acc.includes(item.type)) {
        acc.push(item.type)
      }
      return acc
    }, [])

    return http
      .get('/api/private/v1/campaign-statistics', {
        params: {
          start_date: dates.start,
          end_date: dates.end,
          campaigns: selectedCampaigns,
          ad_formats: adFormatsTypes,
          group: groupBy,
          statuses: campaignStatuses
        },
        cancelToken: new http.CancelToken((abort) => {
          this.list.abort = abort
        })
      })
      .then((response) => response.data.data.map((item) => ({
        clicks: item.clicks,
        conversions: item.conversions,
        cost: item.cost ?? 0,
        winRate: item.win_rate ?? 0,
        reserved_balance: undefinedNumberHandler(item.reserved_balance),
        ctr: item.ctr,
        provider: item.trafficProvider,
        site_id: item.site_id,
        requests: item.requests,
        bids: item.bids,
        wins: item.wins,
        spend: item.spend,
        date: item.date,
        impressions: item.impressions,
        payouts: item.payouts,
        video_views: item.video_views,
        hour: item.hour,
        campaign_id: item.campaign_id,
        campaign: item.campaign,
        trafficProvider: item.trafficProvider,
        traffic_platform: item.campaign_traffic_platform_name,
        id: item.id,
        banner: item.banner,
        type: item.type,
        webPush: item.webPush,
        inPageAd: item.inPageAd,
        inStreamAd: item.inStreamAd,
        nativeAd: item.nativeAd,
        directLink: item.directLink,
        iosCalendar: item.iosCalendar,
        gallery: item.gallery,
        src: item.src,
        creative_id: item.creative_id,
        ad_type_name: item.ad_type_name,
        browser: item.browser,
        carrier: item.carrier,
        country: item.country,
        operatingSystem: item.operatingSystem,
        site: item.site,
        spot_id: item.spot_id,
        tds_id: item.tds_id,
        browser_id: item.browser_id,
        ad_id: item.ad_id
      })))
  }
}
