import settings from '@/settings.js'

export default {
  install(_Vue) {
    const auth = new _Vue({
      data() {
        return {
          isInited: false,
          payload: {}
        }
      },
      methods: {
        async init(pixelPayload) {
          if (!this.inited && settings.pixelUrl) {
            await this.loadScript()
            this.inited = true
          }
          if (pixelPayload) {
            this.setPayload(pixelPayload)
          }
        },
        setPayload(data) {
          if (!data) return

          this.payload = data
          window.__pi.setPayload(data)
        },
        loadScript() {
          return new Promise((resolve) => {
            const script = document.createElement('script')
            script.async = true
            script.src = settings.pixelUrl
            const firstScript = document.getElementsByTagName('script')[0]
            firstScript.parentNode.insertBefore(script, firstScript)
            script.onload = resolve
          })
        }
      }
    })

    _Vue.prototype.$pixel = auth
  }
}
