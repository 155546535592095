import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
import { rotationsTypes } from '@clickadilla/components/constants/rotations-types.js'
import { adTypes } from '@clickadilla/components/constants/ad-types.js'

export default {
  getIsEditing: (state) => !!state.adId && !state.isDuplicate,
  creativeIsSingle: (state) => state.creatives.length === 1,
  getAdFormRequest: (state, getters) => (
    {
      [adFormats.POPUNDER]: () => getters.getPopunderRequest,
      [adFormats.BANNER]: () => getters.getBannerRequest,
      [adFormats.WEB_PUSH]: () => getters.getPushRequest,
      [adFormats.IN_STREAM_AD]: () => getters.getInstreamRequest,
      [adFormats.NATIVE_AD]: () => getters.getNativeRequest,
      [adFormats.DIRECT_LINK]: () => getters.getDirectLinkRequest,
      [adFormats.INTERSTITIAL]: () => getters.getInterstitialsRequest,
      [adFormats.IOS_CALENDAR]: () => getters.getIosCalendarRequest,
      [adFormats.GALLERY]: () => getters.getGalleryRequest,
      [adFormats.IN_PAGE_AD]: () => getters.getInPageRequest
    }[state.adFormatType]() || {}
  ),
  getPopunderRequest: (state) => ({
    ad_format_type: adFormats.POPUNDER,
    name: state.name,
    creatives: state.creatives.map((creative) => ({
      url: creative.url,
      priority: +creative.priority
    }))
  }),
  getBannerRequest: (state, getters) => {
    const requestBody = {
      ad_format_type: adFormats.BANNER,
      name: state.name,
      size: state.size,
      type: state.type,
      rotation_type: rotationsTypes.RANDOM
    }

    if (getters.adTypeIsImage) {
      requestBody.url = state.commonUrl
      requestBody.creatives = state.creatives.map((creative) => ({
        url: creative.url,
        image: creative.image && creative.image.id
      }))
    }

    if (getters.rotationTypeIsSmart) {
      requestBody.impressions_threshold = state.impressionsThreshold
      requestBody.hours_threshold = state.hoursThreshold
    }

    if (getters.adTypeIsSelfcode) {
      requestBody.code = state.code
    }

    if (getters.adTypeIsHtml5) {
      requestBody.creatives = state.creatives.map((creative) => ({
        package: creative.package && creative.package.id
      }))
    }

    if (getters.adTypeIsImage && getters.moreThanOneCreatives) {
      requestBody.rotation_type = state.rotationType
    }

    return requestBody
  },
  getPushRequest: (state, getters) => {
    const body = {
      ad_format_type: adFormats.WEB_PUSH,
      name: state.name,
      creatives: state.creatives.map((creative) => ({
        image: creative.image && creative.image.id,
        body: creative.body,
        icon: creative.icon && creative.icon.id,
        priority: creative.priority,
        title: creative.title,
        url: creative.url,
        button_one: creative.buttonOne,
        button_two: creative.buttonTwo
      })),
      url: state.commonUrl,
      rotation_type: getters.moreThanOneCreatives ? state.rotationType : rotationsTypes.RANDOM
    }

    if (getters.rotationTypeIsSmart) {
      body.impressions_threshold = state.impressionsThreshold
      body.hours_threshold = state.hoursThreshold
    }

    return body
  },
  getInstreamRequest: (state, getters) => {
    const REWARDED_POST_SKIP_TIME = 5

    // TODO fix after change backend param

    let requestData = {
      ad_format_type: adFormats.IN_STREAM_AD,
      name: state.name,
      type: state.type,
      rotation_type: rotationsTypes.RANDOM,
      skip_time: state.skipTime
    }

    if (getters.moreThanOneCreatives) {
      requestData.rotation_type = state.rotationType
    }

    if (getters.rotationTypeIsSmart) {
      requestData.impressions_threshold = state.impressionsThreshold
      requestData.hours_threshold = state.hoursThreshold
    }

    if (state.type === adTypes.VIDEO) {
      requestData = {
        ...requestData,
        creatives: state.creatives.map((creative) => ({
          url: creative.url,
          custom_starting_event_endpoint: creative.startingEventEndpoint,
          video: creative.video && creative.video.id,
          priority: +creative.priority
        }))
      }
    } else if (state.type === adTypes.MOTION_BANNER) {
      requestData = {
        ...requestData,
        creatives: state.creatives.map((creative) => ({
          url: creative.url,
          custom_starting_event_endpoint: creative.startingEventEndpoint,
          image: creative.image && creative.image.id,
          priority: +creative.priority,
          motion_banner_params: {
            fader_color: creative.motionBannerParams.faderColor,
            button_color: creative.motionBannerParams.buttonColor,
            button_hover_color: creative.motionBannerParams.buttonHoverColor,
            button_text_color: creative.motionBannerParams.buttonTextColor,
            button_text_hover_color: creative.motionBannerParams.buttonTextHoverColor,
            button_text: creative.motionBannerParams.buttonText,
            title_text: creative.motionBannerParams.titleText,
            description_text: creative.motionBannerParams.descriptionText,
            additional_text: creative.motionBannerParams.additionalText,
            text_color: creative.motionBannerParams.textColor
          }
        }))
      }
    } else if ([adTypes.WRAPPER_WITH_VIDEO, adTypes.WRAPPER_WITH_VPAID].includes(state.type)) {
      requestData = {
        ...requestData,
        creatives: state.creatives.map((creative) => ({
          vast_tag: creative.vastTagUri,
          priority: +creative.priority
        }))
      }
    } else if ([adTypes.REWARDED_POST].includes(state.type)) {
      requestData = {
        ...requestData,
        skip_time: REWARDED_POST_SKIP_TIME,
        creatives: state.creatives.map((creative) => ({
          url: creative.url,
          custom_starting_event_endpoint: creative.startingEventEndpoint,
          image: creative.image && creative.image.id,
          icon: creative.icon && creative.icon.id,
          priority: +creative.priority,
          rewarded_post_params: {
            brand_text: creative.rewardedPostParams.brandText,
            button_text: creative.rewardedPostParams.buttonText,
            description_text: creative.rewardedPostParams.descriptionText,
            title_text: creative.rewardedPostParams.titleText
          }
        }))
      }
    }
    return requestData
  },
  getNativeRequest: (state) => ({
    ad_format_type: adFormats.NATIVE_AD,
    name: state.name,
    url: state.commonUrl,
    brand: state.brand,
    creatives: state.creatives.map((creative) => ({
      title: creative.title,
      url: creative.url,
      image: creative.image && creative.image.id,
      is_active: creative.isActive,
      description: creative.description
    }))
  }),
  getDirectLinkRequest: (state) => ({
    ad_format_type: adFormats.DIRECT_LINK,
    name: state.name,
    title: state.title,
    url: state.url,
    direct_link_icon_id: state.icon?.id || null
  }),
  getInterstitialsRequest: (state, getters) => {
    const requestBody = {
      ad_format_type: adFormats.INTERSTITIAL,
      name: state.name,
      size: state.size,
      type: state.type
    }

    if (getters.adTypeIsImage || getters.adTypeIsLanding) {
      requestBody.creatives = state.creatives.map((creative) => ({
        url: creative.url,
        image: creative.image && creative.image.id
      }))
      requestBody.rotation_type = getters.moreThanOneCreatives
        ? state.rotationType
        : rotationsTypes.RANDOM
    }

    if (getters.adTypeIsImage) {
      requestBody.url = state.commonUrl
    }

    if (getters.rotationTypeIsSmart) {
      requestBody.impressions_threshold = state.impressionsThreshold
      requestBody.hours_threshold = state.hoursThreshold
    }

    if (getters.adTypeIsSelfcode) {
      requestBody.code = state.code
    }
    return requestBody
  },
  getIosCalendarRequest: (state) => ({
    ad_format_type: adFormats.IOS_CALENDAR,
    name: state.name,
    url: state.commonUrl,
    creatives: state.creatives.map((creative) => ({
      url: creative.url,
      title: creative.title,
      description: creative.description,
      is_active: creative.isActive
    }))
  }),
  getGalleryRequest: (state) => ({
    ad_format_type: adFormats.GALLERY,
    name: state.name,
    content_feed_name: state.contentFeedName,
    content_feed_url: state.contentFeedUrl,
    provider_website: state.providerWebsite
  }),
  getInPageRequest: (state, getters) => {
    const requestBody = {
      ad_format_type: adFormats.IN_PAGE_AD,
      name: state.name,
      url: state.commonUrl,
      rotation_type: getters.moreThanOneCreatives ? state.rotationType : rotationsTypes.RANDOM,
      creatives: state.creatives.map((creative) => ({
        image: creative.image && creative.image.id,
        description: creative.description,
        icon: creative.icon && creative.icon.id,
        priority: creative.priority,
        title: creative.title,
        url: creative.url,
        in_page_skin_id: creative.inPageSkin.id
      }))
    }

    if (getters.rotationTypeIsSmart) {
      requestBody.impressions_threshold = state.impressionsThreshold
      requestBody.hours_threshold = state.hoursThreshold
    }

    return requestBody
  },
  rotationIsRandom(state) {
    return state.rotationType === rotationsTypes.RANDOM
  },
  adTypeIsImage(state) {
    return state.type === adTypes.IMAGE
  },
  adTypeIsHtml5(state) {
    return state.type === adTypes.HTML5
  },
  adTypeIsLanding(state) {
    return state.type === adTypes.LANDING
  },
  adTypeIsSelfcode(state) {
    return state.type === adTypes.SELFCODE
  },
  rotationTypeIsSmart(state) {
    return state.rotationType === rotationsTypes.SMART
  },
  moreThanOneCreatives(state) {
    return state.creatives.length > 1
  },
  creativeTokensLinks(state) {
    const foundUrlTokens = state.creativeTokens.find(({ type }) => type === state.adFormatType)?.creative_tokens ?? []
    return foundUrlTokens.filter(({ type }) => type === 'links').map(({ name }) => name.toUpperCase())
  },
  creativeTokensTexts(state) {
    const foundUrlTokens = state.creativeTokens.find(({ type }) => type === state.adFormatType)?.creative_tokens ?? []
    return foundUrlTokens.filter(({ type }) => type === 'texts').map(({ name }) => name.toUpperCase())
  }
}
