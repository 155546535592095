import AdFormat from '@/services/classes/AdFormat.js'

export default class AdNetwork {
  adFormats = []
  name = ''
  guardSections = []
  googleAnalyticsMeasurementId = ''
  targetTypes = []
  showSiteDomains = true
  telegramBotName = ''
  crispWebsiteId = null
  settings = {
    enablePwa: false,
    themes: {
      light: {}
    },
    socials: {},
    images: {},
    favicons: {},
    pwaFavicon: {},
    helpUrls: {},
    helpVideosUrls: {},
    dashboardGallery: false,
    renameInPageFormat: false,
    spentThisWeek: false,
    showVerificationBanner: true,
    payments: false,
    visibleVoluum: false,
    enableReferralBanners: false,
    referralBanners: {},
    sidebarWidget: {},
    gtmId: '',
    locales: [],
    welcomeScreen: {
      type: '',
      imageUrl: '',
      videoUrl: ''
    },
    apiSelect: '',
    apiUrl: '',
    liveChat: {
      type: '',
      intercom: {},
      tawk: {},
      dashly: {},
      crisp: {}
    },
    ioLink: '',
    adsSpots: {
      marketplace: { type: null },
      dashboardFirst: { type: null },
      dashboardSecond: { type: null },
      dashboardThird: { type: null },
      dashboardFourth: { type: null }
    },
    displayPopup: {
      type: '',
      getsitecontrol: {}
    },
    campaignFormView: '',
    inPagePreviewTagId: null,
    stripeRedirectUrl: '',
    enablePanvyDetails: false,
    defaultAdFormatTargets: {},
    orderAdFormatSortingGroups: [],
    orderAdFormatTypes: [],
    channelsSettings: this.#createChannelsSettings(),
    currencySignIsShown: false
  }

  constructor(data) {
    if (!data) return

    this.adFormats = data.ad_formats?.map((adFormat) => new AdFormat(adFormat)) ?? []
    this.name = data.name ?? ''
    this.guardSections = data.guard_sections ?? []
    this.googleAnalyticsMeasurementId = data.google_analytics_measurement_id ?? ''
    this.targetTypes = data.target_types ?? []
    this.showSiteDomains = data.show_site_domains ?? true
    this.telegramBotName = data.telegram_bot_name ?? ''
    this.crispWebsiteId = data.crisp_website_id ?? null
    this.settings = {
      channelsSettings: this.#createChannelsSettings(data.settings?.channels_settings),
      enablePwa: data.settings?.enable_pwa ?? false,
      themes: {
        light: data.settings?.themes?.light ?? {}
      },
      socials: data.settings?.socials ?? {},
      images: data.settings?.images ?? {},
      favicons: data.settings?.favicons ?? {},
      pwaFavicon: data.settings?.pwa_favicon ?? {},
      helpUrls: data.settings?.help_urls ?? {},
      helpVideosUrls: data.settings?.help_videos_urls ?? {},
      renameInPageFormat: data.settings?.rename_in_page_format ?? false,
      dashboardGallery: data.settings?.dashboard_gallery ?? false,
      spentThisWeek: data.settings?.spent_this_week ?? false,
      showVerificationBanner: data.settings?.show_verification_banner ?? true,
      payments: data.settings?.payments ?? false,
      visibleVoluum: data.settings?.visible_voluum ?? false,
      enableReferralBanners: data.settings?.enable_referral_banners ?? false,
      referralBanners: data.settings?.referral_banners ?? {},
      sidebarWidget: data.settings?.sidebar_widget ?? {},
      gtmId: data.settings?.gtm_id ?? '',
      locales: data.settings?.locales ?? [],
      welcomeScreen: {
        type: data.settings?.welcome_screen?.type ?? '',
        imageUrl: data.settings?.welcome_screen?.image_url ?? '',
        videoUrl: data.settings?.welcome_screen?.video_url ?? ''
      },
      apiSelect: data.settings?.api_select ?? '',
      apiUrl: data.settings?.api_url ?? '',
      liveChat: {
        type: data.settings?.live_chat?.type ?? '',
        intercom: data.settings?.live_chat?.intercom ?? {},
        tawk: data.settings?.live_chat?.tawk ?? {},
        dashly: data.settings?.live_chat?.dashly ?? {},
        crisp: data.settings?.live_chat?.crisp ?? {}
      },
      ioLink: data.settings?.io_link ?? '',
      adsSpots: {
        marketplace: data.settings?.ads_spots?.marketplace ?? { type: null },
        dashboardFirst: data.settings?.ads_spots?.dashboard_first ?? { type: null },
        dashboardSecond: data.settings?.ads_spots?.dashboard_second ?? { type: null },
        dashboardThird: data.settings?.ads_spots?.dashboard_third ?? { type: null },
        dashboardFourth: data.settings?.ads_spots?.dashboard_fourth ?? { type: null }
      },
      displayPopup: {
        type: data.settings?.display_popup?.type ?? '',
        getsitecontrol: data.settings?.display_popup?.getsitecontrol ?? {}
      },
      campaignFormView: data.settings?.campaign_form_view ?? '',
      inPagePreviewTagId: data.settings?.in_page_preview_tag_id ?? null,
      stripeRedirectUrl: data.settings?.stripe_redirect_url ?? '',
      enablePanvyDetails: data.settings?.enable_panvy_details ?? false,
      defaultAdFormatTargets: data.settings?.default_ad_format_targets ?? {},
      orderAdFormatSortingGroups: data.settings?.order_ad_format_sorting_groups ?? [],
      orderAdFormatTypes: data.settings?.order_ad_format_types ?? [],
      currencySignIsShown: data.settings?.currency_sign_is_shown ?? false
    }
  }
  #createChannelsSettings(channelsSettings) {
    return {
      isCustomChannelsTitle: channelsSettings?.is_custom_channels_title ?? false,
      channels: channelsSettings?.channels?.map((channel) => ({
        adFormatsIds: channel.ad_formats_ids,
        isGrouping: channel.is_grouping ?? true,
        nameTranslations: channel.name_translations
      })) ?? []
    }
  }
}
