import getApiHost from '@/services/utils/get-api-host.js'

const apiHost = getApiHost()

export default {
  apiHost,
  fileUploadUrl: `${apiHost}/api/private/v1/upload-file`,
  mediaUploadUrl: `${apiHost}/api/private/v1/media`,
  documentUploadUrl: `${apiHost}/api/private/v1/media/documents`,
  googleSignIn: `${apiHost}/api/private/v1/auth/login/google`,
  feedbackUrl: process.env.VUE_APP_FEEDBACK_URL,
  googleRecaptchaSitekey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITEKEY,
  pixelUrl: process.env.VUE_APP_PIXEL_URL,
  authTokenKey: 'auth_token_default',
  rememberUserCookieKey: 'rememberUser'
}
