import priceboxRepository from '@/services/repositories/pricebox-repository.js'
import sitesRepository from '@/services/repositories/sites-repository.js'
import types from '@/store/trafficChart/common/types.js'
import handleErrors from '@/services/handleErrors.js'

const actions = {
  setSelectRotationAdType({ commit, dispatch }, val) {
    commit(types.SET_SELECT_ROTATION_AD_TYPE, val)
    dispatch('update')
  },
  async update({ commit, state, getters }) {
    const availableTrafficRequest = {
      adFormat: getters.foundAdFormat,
      pricingModel: state.pricingModel,
      countriesIds: state.countriesIds,
      premiumSitesIds: state.premiumSitesIds,
      isPremiumSites: state.isPremiumSites,
      selectRotationAdType: state.selectRotationAdType,
      trafficTypeId: state.trafficTypeId,
      operatingSystemsIds: state.operatingSystemsIds
    }

    commit(types.SET_TRAFFIC_CHART_IS_LOADING, true)
    try {
      const availableTraffic = await priceboxRepository.availableTrafficByCountry(
        availableTrafficRequest
      )
      if (availableTraffic && availableTraffic.countries) {
        commit(types.SET_TRAFFIC, availableTraffic.countries)
        commit(types.SET_TRAFFIC_CHART_IS_LOADING, false)
        return
      }
      commit(types.SET_TRAFFIC, [])
      commit(types.SET_TRAFFIC_CHART_IS_LOADING, false)
    } catch (error) {
      commit(types.SET_TRAFFIC, [])
    }
  },

  setAdFormatId({
    state, dispatch, commit, getters
  }, id) {
    commit(types.SET_AD_FORMAT_ID, id)
    if (state.isPremiumSites) dispatch('fetchPremiumSitesForAdFormat')

    const pricingModelIsAvailable = getters.pricingModels.some(({ value }) => value === state.pricingModel)
    if (!pricingModelIsAvailable && getters.pricingModels.length) {
      dispatch('setPricingModel', getters.pricingModels[0].value)
      return
    }
    if (getters.foundAdFormat) dispatch('update')
  },
  async fetchPremiumSitesForAdFormat({ state, commit }) {
    if (!state.adFormatId || !state.pricingModel) return

    commit(types.SET_PREMIUM_SITES_IS_LOADING, true)
    try {
      const premiumSitesByFormat = await sitesRepository.findPremium({
        adFormatId: state.adFormatId,
        pricingModel: state.pricingModel,
        trafficTypeId: (state.trafficTypeId && state.trafficTypeId.id) || null
      })
      commit(types.SET_PREMIUM_SITES_BY_FORMAT, premiumSitesByFormat)
      commit(
        types.SET_PREMIUM_SITES_IDS,
        premiumSitesByFormat ? premiumSitesByFormat.map((site) => site.id) : []
      )
      commit(types.SET_PREMIUM_SITES_IS_LOADING, false)
    } catch (error) {
      handleErrors(error)
    }
  },
  setPremiumSitesIds({ commit, dispatch }, ids) {
    commit(types.SET_PREMIUM_SITES_IDS, ids)
    dispatch('update')
  },
  setTrafficTypeId({
    state, commit, dispatch, getters
  }, id) {
    commit(types.SET_TRAFFIC_TYPE_ID, id)
    if (state.isPremiumSites) dispatch('fetchPremiumSitesForAdFormat')
    if (getters.foundAdFormat) dispatch('update')
  },
  setOperatingSystemsIds({ commit, dispatch, getters }, ids) {
    commit(types.SET_OPERATING_SYSTEMS_IDS, ids)
    if (getters.foundAdFormat) dispatch('update')
  },
  setCountriesIds({ commit, dispatch, getters }, ids) {
    commit(types.SET_COUNTRIES_IDS, ids)
    if (getters.foundAdFormat) dispatch('update')
  },
  setIsPremiumSites({ commit, dispatch }, isPremiumSites) {
    commit(types.SET_IS_PREMIUM_SITES, isPremiumSites)
    if (isPremiumSites === true) {
      dispatch('fetchPremiumSitesForAdFormat')
      return
    }
    commit(types.SET_PREMIUM_SITES_IDS, [])
    dispatch('update')
  },
  async setPricingModel({ commit, state, dispatch }, pricingModel) {
    commit(types.SET_PRICING_MODEL, pricingModel)
    if (state.isPremiumSites) {
      await dispatch('fetchPremiumSitesForAdFormat')
    }
    if (pricingModel) {
      dispatch('update')
    }
  },
  setCountryPreset: ({ commit }, selectedPreset) => {
    commit(types.SET_COUNTRY_PRESET, selectedPreset)
  }
}

export default actions
