<template>
  <v-app v-if="applicationIsAuth">
    <sidebar :sidebar-shown.sync="sidebarShown" />
    <toolbar :sidebar-shown.sync="sidebarShown" />
    <v-main class="secondary-lighten">
      <v-alert
        v-if="adBlockIsDetected"
        class="text-center ma-0 ma-3"
        color="error"
        dismissible
        dark
      >
        {{ $t('main.adblock_detected') }}.
      </v-alert>
      <email-verification-banner
        v-if="emailVerificationBannerIsShown && adNetwork.settings.showVerificationBanner"
        class="ma-3"
        @close-banner="emailVerificationBannerIsShown = false"
      />
      <v-container fluid class="padding-for-live-chat secondary-lighten">
        <router-view />
      </v-container>
    </v-main>
  </v-app>

  <loader v-else />
</template>

<script>
  import { mapState } from 'vuex'
  import * as Sentry from '@sentry/vue'
  import Sidebar from '@/components/main/Sidebar/Index.vue'
  import Toolbar from '@/components/main/Toolbar.vue'
  import Loader from '@/components/Loader.vue'
  import setGlobalUserId from '@/mixins/setGlobalUserId.js'
  import saveUserToCookie from '@/mixins/saveUserToCookie.js'
  import detectAdBlock from '@/services/utils/detect-ad-block.js'
  import EmailVerificationBanner from '@/components/main/EmailVerificationBanner.vue'

  export default {
    name: 'MainLayouts',
    components: {
      Sidebar,
      Toolbar,
      Loader,
      EmailVerificationBanner
    },
    mixins: [setGlobalUserId, saveUserToCookie],
    data() {
      return {
        sidebarShown: true,
        adBlockIsDetected: false,
        emailVerificationBannerIsShown: false
      }
    },
    computed: {
      ...mapState('settings', ['applicationIsAuth', 'adNetwork']),
      user() {
        return this.$auth.user ?? {}
      }
    },
    async created() {
      this.adBlockIsDetected = await detectAdBlock()
      this.emailVerificationBannerIsShown = this.user.status !== 'confirmed'
      Sentry.setUser({
        id: String(this.user.id),
        username: this.user.name,
        email: this.user.email
      })
      this.initLiveChat()
      this.initDisplayPopup()
    },
    beforeDestroy() {
      if (this.adNetwork.settings.liveChat.type === 'intercom') {
        this.$intercom.shutdown()
      }
    },
    mounted() {
      if (!this.$pixel.payload.user_id && this.user.id) {
        this.$pixel.setPayload({
          user_id: this.user.id,
          is_impersonating: !!this.user.is_impersonating,
          ...!!this.user.is_impersonating && { impersonator_id: this.user.impersonator?.id }
        })
      }
    },
    methods: {
      initLiveChat() {
        const { liveChat } = this.adNetwork.settings
        if (liveChat.type === 'intercom') {
          this.$intercom.init({
            app_id: liveChat.intercom.app_id,
            name: this.user.name,
            user_id: this.user.id,
            email: this.user.email,
            user_hash: this.user.intercom_hash
          })
        } else if (liveChat.type === 'tawk') {
          this.$tawk.init({
            widgetId: liveChat.tawk.widget_id,
            accountKey: liveChat.tawk.account_key,
            name: this.user.name,
            email: this.user.email
          })
        } else if (liveChat.type === 'dashly') {
          this.$dashly.init({
            apiKey: liveChat.dashly.api_key,
            userHash: this.user.dashly_hash,
            userId: this.user.id
          })
        } else if (liveChat.type === 'crisp') {
          this.$crisp.init({
            websiteId: this.adNetwork.crispWebsiteId,
            email: this.user.email,
            emailHash: this.user.crisp_hash
          })
        }
      },
      initDisplayPopup() {
        const { displayPopup } = this.adNetwork.settings
        if (displayPopup.type === 'getsitecontrol' && displayPopup?.getsitecontrol.gsc_id) {
          this.$gsc.init({
            gscId: displayPopup.getsitecontrol.gsc_id,
            name: this.user.name,
            email: this.user.email,
            userId: this.user.id
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .padding-for-live-chat {
    padding-bottom: 90px;
  }
</style>
